<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.content"
    persistent
    no-click-animation
  >
    <close-btn @click="closeDialog()" depressed overflow />

    <v-card class="py-5" v-if="project">
      <v-card-title class="justify-center">
        <h5 class="font-weight-bold text-h5">Move "{{ project.name }}"</h5>
      </v-card-title>
      <v-card-text>

        <v-alert type="error" border="left" v-if="status.error">{{ status.error }}</v-alert>

        <v-form ref="moveFolderForm" @submit.prevent="validateForm()">
          <v-select
            v-model="folder"
            label="Select a folder"
            :rules="[rules.required]"
            :items="filtered"
            item-text="name"
            item-value="id"
            prepend-inner-icon="mdi-folder-outline"
            clearable
            outlined
          ></v-select>

          <v-btn
            @click="validateForm()"
            :loading="status.moving"
            color="accent"
            class="gradient"
            block
          >
            Move
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    show: Boolean,
    project: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      folder: null,
      currentFolder: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.projects.status,
      folders: state => state.folders.folders,
    }),


    filtered: function () {
      return this.folders.filter(folder => {
        return folder.id !== this.currentFolder
      })
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    '$route': function () {
      this.checkCurrentFolder()
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('projects', [
      'updateFolder',
    ]),

    closeDialog() {
      this.$emit('close')
      this.$refs.moveFolderForm.resetValidation()
    },

    validateForm() {
      if (this.$refs.moveFolderForm.validate()) {
        let data = {
          folder: this.folder,
          project: this.project
        }

        Promise.all([this.updateFolder(data)])
        .then(response => {
          if (!response[0]) {
            this.folder = null
            this.closeDialog()
          }
        })
      }
    },

    checkCurrentFolder() {
      if (this.$route.name == 'Folder') {
        this.currentFolder = this.$route.params.id
      }
      else {
        this.currentFolder = 'root'
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.checkCurrentFolder()
  }
}

</script>
