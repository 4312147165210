<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.content"
    persistent
    no-click-animation
  >
    <close-btn @click="close()" depressed overflow />

    <v-card>
      <v-card-title>
        <h5 class="font-weight-bold text-h5">{{ data.id ? 'Rename Folder' : 'Create New Folder' }}</h5>
      </v-card-title>
      <v-card-text>

        <v-alert type="error" border="left" v-if="status.error">{{ status.error }}</v-alert>

        <v-form ref="addFolderForm" @submit.prevent="validateForm()">
          <v-text-field
            v-model="data.name"
            label="Enter your folder name"
            :rules="[rules.required]"
            outlined
          ></v-text-field>

          <v-btn
            @click="validateForm()"
            :loading="status.creating"
            color="accent"
            class="gradient"
            block
          >
            {{ data.id ? 'Update' : 'Create Folder' }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    show: Boolean
  },

  data() {
    return {
      rules,
      oVal: {}
    }
  },

  computed: {
    ...mapState({
      data: state => state.folders.data,
      status: state => state.folders.status,
    })
  },

  methods: {
    ...mapActions('folders', [
      'create',
      'update'
    ]),

    validateForm() {
      if (this.$refs.addFolderForm.validate()) {
        let task

        if (this.data.id) task = Promise.all([this.update()])
        else task = Promise.all([this.create()])

        task.then(response => {
          if (!response[0]) {
            this.$emit('close')
            this.$refs.addFolderForm.resetValidation()
          }
        })
      }
    },

    close() {
      this.$emit('close')
    }
  }
}

</script>
