<template>
  <v-dialog
    :max-width="$dialog.content"
    :value="show"
    no-click-animation
    persistent
  >
    <close-btn @click="closeDialog()" depressed overflow />

    <v-card class="py-5">
      <v-card-title class="justify-center pb-2">
        <h5 class="font-weight-bold text-h5">{{ data.id ? 'Rename Project' : 'Let’s get started!' }}</h5>
      </v-card-title>
      <v-card-text>
        <p class="text-center grey--text body-1" v-if="!data.id">What should we call this project?</p>

        <v-alert type="error" border="left" v-if="status.error">{{ status.error }}</v-alert>

        <v-form ref="addProjectForm" @submit.prevent="validateForm()">
          <v-text-field
            v-model="data.name"
            label="Enter your project name"
            :rules="[rules.required]"
            outlined
          ></v-text-field>

          <v-select
            v-if="!data.id && !folder"
            v-model="data.folder"
            label="Select a folder"
            value="root"
            ref="folderInput"
            :rules="[rules.required]"
            :items="folders"
            item-text="name"
            item-value="id"
            prepend-inner-icon="mdi-folder-outline"
            outlined
          ></v-select>

          <v-row>
            <v-col>
              <v-btn
                @click="validateForm()"
                :loading="status.creating"
                color="accent gradient"
                block
              >
                {{ data.id ? 'Update' : 'Build From Scratch' }}
              </v-btn>
            </v-col>
            
            <v-col>
              <v-btn
                @click="validateForm(true)"
                :loading="status.creating"
                color="primary"
                block
              >
                Select A Template
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    show: Boolean
  },

  data() {
    return {
      rules,
      folder: null,
    }
  },

  computed: {
    ...mapState({
      data: state => state.projects.data,
      status: state => state.projects.status,
      folders: state => state.folders.folders,
    })
  },

  methods: {
    ...mapActions('projects', [
      'create',
      'update'
    ]),

    closeDialog() {
      this.$emit('close')
      this.$store.commit('projects/setData', {})
      this.$store.commit('projects/creatingState', false)
      this.$refs.addProjectForm.resetValidation()
      this.$refs.folderInput.setValue('root')
    },

    validateForm(template = false) {
      if (this.$refs.addProjectForm.validate()) {
        let request

        if (template) this.$store.commit('projects/updateStatus', { selectTemplate: true })

        if (this.data.id) request = Promise.resolve(this.update())
        else request = Promise.resolve(this.create())

        request.then(response => {
          if (!response) {
            this.$emit('close')
            this.$refs.addProjectForm.resetValidation()
          }
        })
      }
    }
  },

  created() {
    if (this.$route.name == 'Folder') {
      this.folder = this.$route.params.id
      this.data.folder = this.folder
    }
    else this.folder = null
  }
}

</script>
