<template>
  <div>
    <router-view />

    <!-- ADD PROJECT -->
    <add-project
      :show="showAddDialog"
      @close="closeAdd()"
    />

    <!-- ADD FOLDER -->
    <add-folder
      :show="folderDialog"
      @close="closeFolderDialog()"
    />

    <!-- MOVE FOLDER -->
    <move-folder
      :show="moveDialog"
      :project="selectedProject"
      @close="closeMoveDialog()"
    />


    <!-- ADD EDIT DIALOG -->
    <AddEditDialog />
    <!-- Collaborators Dialog -->
    <CollaboratorDialog />
  </div>
</template>

<script>

import AddProject from '@/components/AddProject'
import AddFolder from '@/components/AddFolder'
import MoveFolder from '@/components/MoveFolder'
import AddEditDialog from '@/components/AddEditUser'
import CollaboratorDialog from '@/components/CollaboratorDialog'

export default {
  data() {
    return {
      showAddDialog: false,
      folderDialog: false,
      moveDialog: false,
      selectedProject: null,
    }
  },

  components: {
    AddProject,
    AddFolder,
    MoveFolder,
    AddEditDialog,
    CollaboratorDialog,
  },

  methods: {
    showAdd() {
      this.showAddDialog = true
    },

    closeAdd() {
      this.showAddDialog = false
      this.$store.commit('projects/setData', {})
    },

    showFolderDialog() {
      this.folderDialog = true
    },

    closeFolderDialog() {
      this.folderDialog = false
    },

    moveFolder(project) {
      this.selectedProject = project
      this.moveDialog = true
    },

    closeMoveDialog() {
      this.moveDialog = false
    }
  }
}

</script>
